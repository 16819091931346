import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import '@fortawesome/fontawesome-svg-core/styles.css'
import './layout.css'

import Header from './header'
import Footer from './footer'

const Body = styled.main`
  margin: 2em auto 0;
  width: 50vw;
  max-width: 960;
  padding: 0px 1.0875rem 1.45rem;
  padding-type: 0;
  font-family: Roboto, georgia, serif;
  @media only screen and (max-width: 768px) {
    width: 90vw;
  }
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            description
            name
            siteUrl
            theme_color
            title
            social {
              twitter
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet>
          <title>{data.site.siteMetadata.title}</title>
          <html lang="en" />

          <meta
            name="description"
            content={data.site.siteMetadata.description}
          />

          {/* Open Graph */}

          <meta
            name="og:description"
            content={data.site.siteMetadata.description}
          />
          <meta name="og:site_name" content={data.site.siteMetadata.name} />
          <meta name="og:title" content={data.site.siteMetadata.name} />
          <meta name="og:type" content="website" />
          <meta name="og:url" content={data.site.siteMetadata.siteUrl} />

          {/* Google */}
          <meta
            name="google-site-verification"
            content="KfnbML_Vq8CsaRbOpcgmaHHoSFSREYbFVSJ0UcToZJQ"
          />

          {/* Twitter */}
          <meta name="twitter:card" content="summary" />
          <meta
            nam="twitter:site"
            content={data.site.siteMetadata.social.twitter}
          />
          <meta name="twitter:title" content={data.site.siteMetadata.name} />
          <meta
            name="twitter:description"
            content={data.site.siteMetadata.description}
          />
        </Helmet>

        <Header siteTitle={data.site.siteMetadata.title} />
        <Body className="content">{children}</Body>
        <Footer social={data.site.siteMetadata.social} />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
