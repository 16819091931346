import React from 'react'
import styled from 'styled-components'
import { Link, graphql, StaticQuery } from 'gatsby'
import logo from '../images/raizean-logo.png'

const HeaderStyle = styled.header`
  background: var(--brand);
  text-align: center;
  position: relative;
  z-index: 999;
  width: 100%;
  min-height: 105px;

  .logo {
    width: 90px;
    margin: 1em;
  }

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr auto minmax(600px, 3fr) 1fr;

    .logo {
      grid-column: 2 / 3;
    }
  }
`

const Nav = styled.nav`
  position: absolute;
  text-align: left;
  top: 100%;
  left: 0;
  background: var(--brand);
  width: 100%;
  transform: scale(1, 0);
  transform-origin: top;
  transition: transform 400ms ease-in-out;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin-bottom: 1em;
    margin-left: 1em;
  }

  a {
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    white-space: nowrap;
    font-size: 0.85em;
    opacity: 0;
    transition: opacity 150ms ease-in-out;
  }

  a:hover {
    color: #000;
  }

  @media screen and (min-width: 768px) {
    all: unset;
    grid-column: 3 / 4;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    ul {
      display: flex;
    }

    li {
      margin-left: 3em;
      margin-bottom: 0;
    }

    a {
      opacity: 1;
      position: relative;
    }

    a::before {
      content: '';
      display: block;
      height: 5px;
      background: black;
      position: absolute;
      top: -0.75em;
      left: 0;
      right: 0;
      transform: scale(0, 1);
      transition: transform ease-in-out 250ms;
    }

    a:hover::before {
      transform: scale(1, 1);
    }
  }
`

const NavToggleLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 1em;
  height: 100%;
  display: flex;
  align-items: center;

  span,
  span::before,
  span::after {
    display: block;
    background: white;
    height: 2px;
    width: 2em;
    border-radius: 2px;
    position: relative;
  }

  span::before,
  span::after {
    content: '';
    position: absolute;
  }

  span::before {
    bottom: 7px;
  }

  span::after {
    top: 7px;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
`

const NavToggle = styled.input`
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;

  :focus ~ .nav-toggle-label {
    outline: 3px solid rgba(lightblue, 0.75);
  }

  :checked ~ nav {
    transform: scale(1, 1);
  }

  :checked ~ nav a {
    opacity: 1;
    transition: opacity 250ms ease-in-out 250ms;
  }
`

const Header = ({ siteTitle, data }) => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "raizean-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 90) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <HeaderStyle>
        <Link to="/">
          <img src={logo} alt="Raizean logo" className="logo" />
          {/* TODO: Add logo here to improve image loading */}
        </Link>

        <NavToggle
          type="checkbox"
          id="nav-toggle"
          className="nav-toggle"
          aria-label="Navigation menu toggle"
        />
        <Nav>
          <ul>
            <li>
              <Link to="#who-we-are">Our Story</Link>
            </li>
            <li>
              <Link to="#what-we-do">Why Raizean</Link>
            </li>
            <li>
              <Link to="#contact">Contact Us</Link>
            </li>
          </ul>
        </Nav>
        <NavToggleLabel htmlFor="nav-toggle" className="nav-toggle-label">
          <span />
        </NavToggleLabel>
      </HeaderStyle>
    )}
  />
)

export default Header
