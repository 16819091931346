import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'

const Header = styled.h3`
  text-align: center;
`

const NotFoundPage = () => (
  <Layout>
    <Header>Sorry, it looks like this page doesn't exist.</Header>
  </Layout>
)

export default NotFoundPage
